.Content {
  min-height: 80vh;
  padding: 4rem 8rem !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.Content svg {
  margin-bottom: 5rem;
}
