.FullscreenWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7f7;
}

.AuthRoot {
  background-color: #fff;
  max-width: 500px;
  margin: 0rem auto;
  padding: 4rem;
  display: flex;
  flex-direction: column;
}

.AuthRoot h2 {
  font-size: 32px;
  color: #2e2f30;
  letter-spacing: 0.8px;
  line-height: 32px;
  margin-bottom: 4rem;
  text-align: center;
}

.AuthRoot p {
  font-size: 16px;
  color: #2e2f30;
  letter-spacing: 0.34px;
  text-align: center;
  line-height: 20px;
}

.submit {
  margin: auto;
}

.AuthRoot input {
  width: 80%;
  max-width: 400px;
  margin: 2rem auto;
  font-size: 16px;
  border: 1px solid #e9e9f0;
  border-radius: 4px;
  padding: 1rem;
}

.info {
  margin: auto;
  margin-top: 2rem;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  display: flex;
}

.success {
  color: #0e68d9;
}

.error {
  color: #ff6060;
}
