.TocRoot {
  background: var(--theme-colors-background);
}

.TocContainer {
  width: 300px;
  height: 100%;
  overflow-y: auto;
}

.TocHeader {
  padding: 4px 8px 16px 8px;
  display: flex;
  justify-content: center;
}

.TocHeader img {
  margin: 12px 18px 0px 16px;
}

.TocHeader .Description {
  display: block;
  font-size: 11px;
  color: #999999;
  letter-spacing: 1px;
}

.TocHeader .Name {
  font-size: 14px;
  color: #f8f8f8;
  letter-spacing: 0.88px;
}

.Toc {
  width: 100%;
  background: var(--theme-colors-background);
}

.Toc.ui.accordion .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 22px;
  padding-left: 22px;
  text-align: left;
  border-top: 1px solid #4a4a4a;
}

.Toc.ui.accordion .title .dropdown.icon {
  color: #4a4a4a;
}

.Toc .ChapterTocItem {
  display: flex;
  flex-direction: row;
  padding: 12px 0 12px 16px;
  flex: 1;
}

.Toc .ChapterTocItem .ChapterNameContainer {
  flex: 1;
}

.Toc .ChapterTocItem h3 {
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Oxygen", sans-serif !important;
  font-size: 12px !important;
  color: #1ca7bd;
  letter-spacing: 1px;
}

.Toc .ChapterTocItem span {
  font-size: 11px;
  color: var(--theme-colors-typography);
  letter-spacing: 1px;
}

.Toc .ChapterTocContent {
  background: var(--theme-colors-background);
  filter: brightness(80%);
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.Toc .ChapterTocContent button {
  color: #ff9c47;
  display: block;
  margin-left: 32px;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  background: none;
  border: none;
  outline: none;
}

.Toc .ChapterTocContent .BlockItem {
  font-size: 12px;
  color: #999999;
  letter-spacing: 0.92px;
  font-weight: 300;
  margin: 8px 12px 8px 32px;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  display: flex;
}
.Toc .ChapterTocContent .BlockItem .ActiveBlockItem {
  color: var(--theme-colors-secondary);
}

.Toc .ChapterTocContent .BlockItem .InActiveBlockItem {
  color: var(--theme-colors-typography);
}

.Toc .Scheduling {
  display: flex;
  justify-content: center;
}

.Toc .TocTitle {
  color: white;
  font-weight: 500;
  letter-spacing: 0px;
  margin: 30px 16px;
  display: block;
  text-align: center;
  font-size: 13px;
}

.Toc.ui.accordion .title ~ .content:not(.ui) {
  padding: 0 !important;
}

.Toc.ui.accordion:nth-last-child(1) {
  border-bottom: 1px solid #4a4a4a;
}

.MobileToc {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--theme-colors-background);
  padding-bottom: 32px;
  overflow-y: auto;
  overflow-scrolling: touch;
}

.TocHandbookName {
  text-align: center;
}
