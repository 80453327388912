.Bubble {
  width: 117px;
  height: 42px;
  border-radius: 6px !important;
  background: #154dcf !important;
  color: white;
  position: fixed;
  right: 4rem;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  background: #ffffff;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
}

.Bubble.Mobile {
  right: 1rem;
  bottom: 1rem;
  transition: all 0.1s;
}

.Bubble.Mobile.Highlighted {
  right: 3rem;
  bottom: 1rem;
  transition: all 0s;
}

.BubbleText {
  line-height: 20px;
}

.Bubble svg {
  fill: #0d48d2;
  margin-left: 0.5rem;
}

.Bubble:hover {
  transform: scale(1.025);
  background: #0d48d2;
  transition: all 0.2s ease-in;
}

.Bubble:active {
  transform: scale(0.98);
}

.Bubble.Confirmed {
  background: none !important;
  color: #154dcf;
  box-shadow: none;
}

.PopupContent {
  font-family: "Poppins", sans-serif !important;
  padding: 1rem;
}

.PopupContent .Header {
  display: flex;
  font-family: "Poppins", sans-serif !important;
  justify-content: space-between;
}

.PopupContent .Header h4 {
  font-family: "Poppins", sans-serif !important;
}

.PopupContent .Header svg {
  cursor: pointer;
}

.ConfirmedModal {
  font-family: "Poppins", sans-serif !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0rem;
  text-align: center;
}

.ConfirmedModal h3 {
  font-family: "Poppins", sans-serif !important;
  text-align: center;
}
