.header {
  height: 70px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 2rem;
}

.header svg {
  width: 50%;
}
