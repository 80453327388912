@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap);
html,
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

span {
  font-family: "Poppins", sans-serif;
  color: #4a4a4a;
}

h1 {
  font-size: 48px;
  font-family: "Poppins", sans-serif;
}

h2,
.h2 {
  font-size: 1.71428571rem !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: bold !important;
  margin: 0;
}

h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin: 0;
}

input {
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  letter-spacing: 1px;
}

button,
textarea,
.ui.checkbox {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.ui.page.dimmer {
  background: #f7f7f7 !important;
}

.ui.modal {
  box-shadow: none !important;
}

.Toastify__toast {
  border-radius: 6px !important;
}

.Toastify__toast--error {
  background: #ff6060 !important;
}

a,
a:hover {
  color: var(--theme-colors-primary) !important;
}

a:hover {
  -webkit-filter: brightness(90%) !important;
          filter: brightness(90%) !important;
}

.Modal_Content__228Dh {
  min-height: 80vh;
  padding: 4rem 8rem !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.Modal_Content__228Dh svg {
  margin-bottom: 5rem;
}

.PrimaryButton_primaryButton__33L35 {
    background: #0D48D2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1rem;
    padding: 1rem 2rem;
    border-radius: 4px;
    font-weight: 600;
    letter-spacing: 0.35px;
    text-align: center;
    line-height: 22px;
    text-transform: uppercase;
    border: none;
}

.PrimaryButton_primaryButton__33L35 svg {
    margin-right: 1rem;
}
.Auth_FullscreenWrapper__1wXZY {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7f7;
}

.Auth_AuthRoot__a9h1j {
  background-color: #fff;
  max-width: 500px;
  margin: 0rem auto;
  padding: 4rem;
  display: flex;
  flex-direction: column;
}

.Auth_AuthRoot__a9h1j h2 {
  font-size: 32px;
  color: #2e2f30;
  letter-spacing: 0.8px;
  line-height: 32px;
  margin-bottom: 4rem;
  text-align: center;
}

.Auth_AuthRoot__a9h1j p {
  font-size: 16px;
  color: #2e2f30;
  letter-spacing: 0.34px;
  text-align: center;
  line-height: 20px;
}

.Auth_submit__174pK {
  margin: auto;
}

.Auth_AuthRoot__a9h1j input {
  width: 80%;
  max-width: 400px;
  margin: 2rem auto;
  font-size: 16px;
  border: 1px solid #e9e9f0;
  border-radius: 4px;
  padding: 1rem;
}

.Auth_info__3fIHQ {
  margin: auto;
  margin-top: 2rem;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  display: flex;
}

.Auth_success__6sJgh {
  color: #0e68d9;
}

.Auth_error__ZIB1v {
  color: #ff6060;
}

.Handbook {
  height: 100%;
  overflow: hidden;
}

.Handbook.Desktop {
  display: flex;
}

.Handbook .MobileToc {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--theme-colors-background);
  padding-bottom: 32px;
  overflow-y: auto;
  overflow-scrolling: touch;
}

.Handbook.MobileHandbook {
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.NextChapterContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.NextChapterContent img {
  width: 30%;
  margin: 64px 0;
}

.NextChapterContent h2 {
  font-size: 34px !important;
  line-height: 1.6;
}

.NextChapterContent p {
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
}

.Handbook .PreviewHeader {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: white;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.Handbook .PreviewHeader .Caption {
  font-size: 16px;
}

.Handbook .Sidebar {
  background: #353535 !important;
  width: 350px;
  padding: 0;
  overflow-y: hidden !important;
  overflow-x: hidden;
}

.Handbook .Sidebar .TocContainer {
  width: 376px;
  height: 100%;
  overflow-y: auto;
}

.Handbook .TocTitle {
  color: white;
  font-weight: 500;
  letter-spacing: 0px;
  margin: 30px 16px;
  display: block;
  text-align: center;
  font-size: 13px;
}

#MobileHandbook .TocTitle {
  text-align: center;
}

.Handbook .Sidebar h2 {
  margin-top: 72px;
  margin-bottom: 16px;
  color: white;
}

#DesktopHandbookContent {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1;
}

/* Text styles */

.Handbook h1 {
  font-size: 3rem;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  font-weight: normal;
}

.Handbook h2 {
  font-size: 2.5rem;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.Handbook .BlockFeedback {
  background: #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 110px;
}

.Handbook .Selections {
  display: flex;
  flex-direction: row;
}

.Handbook .Selections button {
  background: none;
  padding: 12px;
  font-size: 3rem;
  border: none;
  outline: none;
}

.Handbook .Selections button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  cursor: pointer;
}

.Handbook .ShortcutBanner {
  padding: 16px 24px;
  background: rgb(12, 11, 76);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Handbook .ShortcutBanner span {
  color: #bababa;
}

.Handbook .ShortcutBanner span a {
  color: white;
  font-weight: 700;
  padding: 12px;
}

.ui.checkbox {
  font-weight: 600;
  color: #000;
  opacity: 0.7;
  margin-bottom: 2rem;
  font-family: inherit !important;
}

.ui.checkbox input:focus ~ label:before {
  border-color: #b3b6bb;
}

.PrimaryButton {
  background: #0d48d2;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: none;
  padding: 10px 16px;
  color: white;
  font-size: 14px;
  outline: none;

  cursor: pointer;
}

.PrimaryButton:hover {
  opacity: 0.85;
}

.PrimaryButton.Disabled {
  background: #b3b6bb;
}

.SecondaryButton {
  background: #101e3f;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: none;
  padding: 10px 16px;
  color: white;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}

.SecondaryButton:hover {
  opacity: 0.85;
}

.SecondaryButton.Disabled {
  background: #b3b6bb;
}

.ToolbarButton {
  background: none;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ToolbarButton:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.ToolbarButton .Content {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 20px;
  background: var(--theme-colors-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.TocRoot {
  background: var(--theme-colors-background);
}

.TocContainer {
  width: 300px;
  height: 100%;
  overflow-y: auto;
}

.TocHeader {
  padding: 4px 8px 16px 8px;
  display: flex;
  justify-content: center;
}

.TocHeader img {
  margin: 12px 18px 0px 16px;
}

.TocHeader .Description {
  display: block;
  font-size: 11px;
  color: #999999;
  letter-spacing: 1px;
}

.TocHeader .Name {
  font-size: 14px;
  color: #f8f8f8;
  letter-spacing: 0.88px;
}

.Toc {
  width: 100%;
  background: var(--theme-colors-background);
}

.Toc.ui.accordion .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 22px;
  padding-left: 22px;
  text-align: left;
  border-top: 1px solid #4a4a4a;
}

.Toc.ui.accordion .title .dropdown.icon {
  color: #4a4a4a;
}

.Toc .ChapterTocItem {
  display: flex;
  flex-direction: row;
  padding: 12px 0 12px 16px;
  flex: 1 1;
}

.Toc .ChapterTocItem .ChapterNameContainer {
  flex: 1 1;
}

.Toc .ChapterTocItem h3 {
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Oxygen", sans-serif !important;
  font-size: 12px !important;
  color: #1ca7bd;
  letter-spacing: 1px;
}

.Toc .ChapterTocItem span {
  font-size: 11px;
  color: var(--theme-colors-typography);
  letter-spacing: 1px;
}

.Toc .ChapterTocContent {
  background: var(--theme-colors-background);
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.Toc .ChapterTocContent button {
  color: #ff9c47;
  display: block;
  margin-left: 32px;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  background: none;
  border: none;
  outline: none;
}

.Toc .ChapterTocContent .BlockItem {
  font-size: 12px;
  color: #999999;
  letter-spacing: 0.92px;
  font-weight: 300;
  margin: 8px 12px 8px 32px;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  display: flex;
}
.Toc .ChapterTocContent .BlockItem .ActiveBlockItem {
  color: var(--theme-colors-secondary);
}

.Toc .ChapterTocContent .BlockItem .InActiveBlockItem {
  color: var(--theme-colors-typography);
}

.Toc .Scheduling {
  display: flex;
  justify-content: center;
}

.Toc .TocTitle {
  color: white;
  font-weight: 500;
  letter-spacing: 0px;
  margin: 30px 16px;
  display: block;
  text-align: center;
  font-size: 13px;
}

.Toc.ui.accordion .title ~ .content:not(.ui) {
  padding: 0 !important;
}

.Toc.ui.accordion:nth-last-child(1) {
  border-bottom: 1px solid #4a4a4a;
}

.MobileToc {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--theme-colors-background);
  padding-bottom: 32px;
  overflow-y: auto;
  overflow-scrolling: touch;
}

.TocHandbookName {
  text-align: center;
}

.ConfirmEnroll_NextHandbook__3pDsb div {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.ConfirmEnroll_NextHandbook__3pDsb div b {
  font-weight: 800;
}

.ConfirmEnroll_ConfirmBoxContent__2brCT {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  background: #f1f1f1;
  border-radius: 4px;
}

.ConfirmEnroll_ConfirmBoxContent__2brCT p {
  margin: 48px 16px;
}

.ConfirmEnroll_ConfirmBoxContent__2brCT h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem !important;
  margin-top: 2rem;
}

.ConfirmEnroll_ConfirmBoxContent__2brCT div {
  margin-bottom: 2rem;
}

.ConfirmEnroll_ConfirmBoxContainer__1HfrJ {
  max-width: 50vw;
  margin: 0 auto;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.ConfirmEnroll_ConfirmBoxContainer__1HfrJ.ConfirmEnroll_Confirmed__3aSZn {
  text-align: center !important;
}

.ConfirmEnroll_ConfirmBoxContent__2brCT .ConfirmEnroll_ui__1zO0x.ConfirmEnroll_checkbox__2KuWN label {
  color: #0e68d9 !important;
}

.HandbookListCard_card__2zG1h {
  background: #ffffff;
  border: 1px solid #e9e9f0;
  box-shadow: 0 0 10px 0 rgba(179, 182, 187, 0.05);
  border-radius: 8px;
  padding: 2rem 1.5rem;
  display: grid;
  grid-template-columns: 50% 40% auto;
  text-decoration: none;
}

.HandbookListCard_svgContainer__3MrCg {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.HandbookListCard_title__14Gwx {
  font-family: "Oswald", sans-serif;
  font-size: 15px;
  color: #161616;
  letter-spacing: 0.3px;
  line-height: 14px;
  margin: 0.3rem 0;
  text-align: left;
  display: left;
  align-items: center;
}

.HandbookListCard_titleContainer__1po5B {
  display: flex;
  flex-direction: column;
}

.HandbookListCard_minor__3woI- {
  font-family: "Oswald", sans-serif;
  font-size: 12px;
  color: #8b8e8f;
  letter-spacing: 0.3px;
  text-align: left;
}

.HandbookListCard_rightContainer__15eZR {
  display: flex;
  align-items: center;
}

.HandbookListCard_minorLabel__20m30 {
  display: block;
  font-family: "Oswald", sans-serif;
  font-size: 12px;
  color: #b3b6bb;
  letter-spacing: 0.3px;
  text-align: left;
}

.HandbookListCard_success__2o7QN {
  color: #0e68d9;
}

.HandbookListCard_card__2zG1h.HandbookListCard_success__2o7QN {
  border: 2px solid #0e68d9;
}

.Header_header__3mObx {
  height: 70px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 2rem;
}

.Header_header__3mObx svg {
  width: 50%;
}

.Library_LibraryRoot__3vRX- {
  max-width: 500px;
  margin: 4rem auto;
  padding: 0 1rem;
}

.Library_LibraryRoot__3vRX- h2 {
  font-size: 32px;
  color: #000;
  letter-spacing: 0.8px;
  line-height: 32px;
  margin-bottom: 4rem;
}

.Library_LibraryRoot__3vRX- h5 {
  text-align: left;
}

.Library_LibraryRoot__3vRX- p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #000;
  letter-spacing: 0.34px;
  text-align: center;
  line-height: 20px;
}

.Library_handbookSection__3aSZv {
  margin-top: 4rem;
}

.Library_handbookContainer__2CvFG {
  margin: 2rem auto;
}

.Library_handbookItem__3SE9t {
  margin-bottom: 1rem;
}

.Library_Onboarding__2Cmhc button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Library_OnboardingDescription__1xc0H {
  margin: 1rem 0 2.5rem;
  max-width: 400px;
}

.ui.popup {
  background: #0d48d2;
  border-color: #0d48d2;
  color: white;
  border-radius: 8px;
}

.ui.top.popup:before {
  background: #0d48d2;
  border-color: #0d48d2;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  bottom: -6px !important;
  box-shadow: none;
}

.ui.popup.highlight.center {
  margin: 0;
  width: 54px;
  height: 54px;
  border-radius: 27px;
  background: white;
  opacity: 0.5;
  border: none;
  box-shadow: 0px 0px 15px 5px #424242;
  pointer-events: none;
}

.ui.popup.highlight:before {
  display: none;
}

.ui.page.dimmer {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.ui.small.modal > .content,
.ui.small.modal {
  border-radius: 8px;
}

.ui.modal > .close.icon {
  opacity: 1;
}

.close.icon > svg {
  stroke: black;
}

.ReadConfirmationWidget_Bubble__1pgso {
  width: 117px;
  height: 42px;
  border-radius: 6px !important;
  background: #154dcf !important;
  color: white;
  position: fixed;
  right: 4rem;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  background: #ffffff;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
}

.ReadConfirmationWidget_Bubble__1pgso.ReadConfirmationWidget_Mobile__25cBY {
  right: 1rem;
  bottom: 1rem;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

.ReadConfirmationWidget_Bubble__1pgso.ReadConfirmationWidget_Mobile__25cBY.ReadConfirmationWidget_Highlighted__3YrO_ {
  right: 3rem;
  bottom: 1rem;
  -webkit-transition: all 0s;
  transition: all 0s;
}

.ReadConfirmationWidget_BubbleText__2PGJ2 {
  line-height: 20px;
}

.ReadConfirmationWidget_Bubble__1pgso svg {
  fill: #0d48d2;
  margin-left: 0.5rem;
}

.ReadConfirmationWidget_Bubble__1pgso:hover {
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
  background: #0d48d2;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.ReadConfirmationWidget_Bubble__1pgso:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.ReadConfirmationWidget_Bubble__1pgso.ReadConfirmationWidget_Confirmed__37a0j {
  background: none !important;
  color: #154dcf;
  box-shadow: none;
}

.ReadConfirmationWidget_PopupContent__1Truj {
  font-family: "Poppins", sans-serif !important;
  padding: 1rem;
}

.ReadConfirmationWidget_PopupContent__1Truj .ReadConfirmationWidget_Header__3mdzi {
  display: flex;
  font-family: "Poppins", sans-serif !important;
  justify-content: space-between;
}

.ReadConfirmationWidget_PopupContent__1Truj .ReadConfirmationWidget_Header__3mdzi h4 {
  font-family: "Poppins", sans-serif !important;
}

.ReadConfirmationWidget_PopupContent__1Truj .ReadConfirmationWidget_Header__3mdzi svg {
  cursor: pointer;
}

.ReadConfirmationWidget_ConfirmedModal__Uh9cZ {
  font-family: "Poppins", sans-serif !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0rem;
  text-align: center;
}

.ReadConfirmationWidget_ConfirmedModal__Uh9cZ h3 {
  font-family: "Poppins", sans-serif !important;
  text-align: center;
}

