.Handbook {
  height: 100%;
  overflow: hidden;
}

.Handbook.Desktop {
  display: flex;
}

.Handbook .MobileToc {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--theme-colors-background);
  padding-bottom: 32px;
  overflow-y: auto;
  overflow-scrolling: touch;
}

.Handbook.MobileHandbook {
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.NextChapterContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.NextChapterContent img {
  width: 30%;
  margin: 64px 0;
}

.NextChapterContent h2 {
  font-size: 34px !important;
  line-height: 1.6;
}

.NextChapterContent p {
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
}

.Handbook .PreviewHeader {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: white;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.Handbook .PreviewHeader .Caption {
  font-size: 16px;
}

.Handbook .Sidebar {
  background: #353535 !important;
  width: 350px;
  padding: 0;
  overflow-y: hidden !important;
  overflow-x: hidden;
}

.Handbook .Sidebar .TocContainer {
  width: 376px;
  height: 100%;
  overflow-y: auto;
}

.Handbook .TocTitle {
  color: white;
  font-weight: 500;
  letter-spacing: 0px;
  margin: 30px 16px;
  display: block;
  text-align: center;
  font-size: 13px;
}

#MobileHandbook .TocTitle {
  text-align: center;
}

.Handbook .Sidebar h2 {
  margin-top: 72px;
  margin-bottom: 16px;
  color: white;
}

#DesktopHandbookContent {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

/* Text styles */

.Handbook h1 {
  font-size: 3rem;
  hyphens: auto;
  font-weight: normal;
}

.Handbook h2 {
  font-size: 2.5rem;
  hyphens: auto;
}

.Handbook .BlockFeedback {
  background: #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 110px;
}

.Handbook .Selections {
  display: flex;
  flex-direction: row;
}

.Handbook .Selections button {
  background: none;
  padding: 12px;
  font-size: 3rem;
  border: none;
  outline: none;
}

.Handbook .Selections button:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.Handbook .ShortcutBanner {
  padding: 16px 24px;
  background: rgb(12, 11, 76);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Handbook .ShortcutBanner span {
  color: #bababa;
}

.Handbook .ShortcutBanner span a {
  color: white;
  font-weight: 700;
  padding: 12px;
}

.ui.checkbox {
  font-weight: 600;
  color: #000;
  opacity: 0.7;
  margin-bottom: 2rem;
  font-family: inherit !important;
}

.ui.checkbox input:focus ~ label:before {
  border-color: #b3b6bb;
}
