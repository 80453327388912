@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

span {
  font-family: "Poppins", sans-serif;
  color: #4a4a4a;
}

h1 {
  font-size: 48px;
  font-family: "Poppins", sans-serif;
}

h2,
.h2 {
  font-size: 1.71428571rem !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: bold !important;
  margin: 0;
}

h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin: 0;
}

input {
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  letter-spacing: 1px;
}

button,
textarea,
.ui.checkbox {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.ui.page.dimmer {
  background: #f7f7f7 !important;
}

.ui.modal {
  box-shadow: none !important;
}

.Toastify__toast {
  border-radius: 6px !important;
}

.Toastify__toast--error {
  background: #ff6060 !important;
}

a,
a:hover {
  color: var(--theme-colors-primary) !important;
}

a:hover {
  filter: brightness(90%) !important;
}
