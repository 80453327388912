.PrimaryButton {
  background: #0d48d2;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: none;
  padding: 10px 16px;
  color: white;
  font-size: 14px;
  outline: none;

  cursor: pointer;
}

.PrimaryButton:hover {
  opacity: 0.85;
}

.PrimaryButton.Disabled {
  background: #b3b6bb;
}

.SecondaryButton {
  background: #101e3f;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: none;
  padding: 10px 16px;
  color: white;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}

.SecondaryButton:hover {
  opacity: 0.85;
}

.SecondaryButton.Disabled {
  background: #b3b6bb;
}

.ToolbarButton {
  background: none;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ToolbarButton:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;
}

.ToolbarButton .Content {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 20px;
  background: var(--theme-colors-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}
