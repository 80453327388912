.NextHandbook div {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.NextHandbook div b {
  font-weight: 800;
}

.ConfirmBoxContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  background: #f1f1f1;
  border-radius: 4px;
}

.ConfirmBoxContent p {
  margin: 48px 16px;
}

.ConfirmBoxContent h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem !important;
  margin-top: 2rem;
}

.ConfirmBoxContent div {
  margin-bottom: 2rem;
}

.ConfirmBoxContainer {
  max-width: 50vw;
  margin: 0 auto;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.ConfirmBoxContainer.Confirmed {
  text-align: center !important;
}

.ConfirmBoxContent .ui.checkbox label {
  color: #0e68d9 !important;
}
