.ui.popup {
  background: #0d48d2;
  border-color: #0d48d2;
  color: white;
  border-radius: 8px;
}

.ui.top.popup:before {
  background: #0d48d2;
  border-color: #0d48d2;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  bottom: -6px !important;
  box-shadow: none;
}

.ui.popup.highlight.center {
  margin: 0;
  width: 54px;
  height: 54px;
  border-radius: 27px;
  background: white;
  opacity: 0.5;
  border: none;
  box-shadow: 0px 0px 15px 5px #424242;
  pointer-events: none;
}

.ui.popup.highlight:before {
  display: none;
}

.ui.page.dimmer {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.ui.small.modal > .content,
.ui.small.modal {
  border-radius: 8px;
}

.ui.modal > .close.icon {
  opacity: 1;
}

.close.icon > svg {
  stroke: black;
}
