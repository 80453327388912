.card {
  background: #ffffff;
  border: 1px solid #e9e9f0;
  box-shadow: 0 0 10px 0 rgba(179, 182, 187, 0.05);
  border-radius: 8px;
  padding: 2rem 1.5rem;
  display: grid;
  grid-template-columns: 50% 40% auto;
  text-decoration: none;
}

.svgContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.title {
  font-family: "Oswald", sans-serif;
  font-size: 15px;
  color: #161616;
  letter-spacing: 0.3px;
  line-height: 14px;
  margin: 0.3rem 0;
  text-align: left;
  display: left;
  align-items: center;
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.minor {
  font-family: "Oswald", sans-serif;
  font-size: 12px;
  color: #8b8e8f;
  letter-spacing: 0.3px;
  text-align: left;
}

.rightContainer {
  display: flex;
  align-items: center;
}

.minorLabel {
  display: block;
  font-family: "Oswald", sans-serif;
  font-size: 12px;
  color: #b3b6bb;
  letter-spacing: 0.3px;
  text-align: left;
}

.success {
  color: #0e68d9;
}

.card.success {
  border: 2px solid #0e68d9;
}
