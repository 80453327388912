.LibraryRoot {
  max-width: 500px;
  margin: 4rem auto;
  padding: 0 1rem;
}

.LibraryRoot h2 {
  font-size: 32px;
  color: #000;
  letter-spacing: 0.8px;
  line-height: 32px;
  margin-bottom: 4rem;
}

.LibraryRoot h5 {
  text-align: left;
}

.LibraryRoot p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #000;
  letter-spacing: 0.34px;
  text-align: center;
  line-height: 20px;
}

.handbookSection {
  margin-top: 4rem;
}

.handbookContainer {
  margin: 2rem auto;
}

.handbookItem {
  margin-bottom: 1rem;
}

.Onboarding button {
  width: fit-content;
}

.OnboardingDescription {
  margin: 1rem 0 2.5rem;
  max-width: 400px;
}
