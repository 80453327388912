.primaryButton {
    background: #0D48D2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1rem;
    padding: 1rem 2rem;
    border-radius: 4px;
    font-weight: 600;
    letter-spacing: 0.35px;
    text-align: center;
    line-height: 22px;
    text-transform: uppercase;
    border: none;
}

.primaryButton svg {
    margin-right: 1rem;
}